<template>
    <div id="app">
        <metainfo />
        <component :is="layout">
            <router-view />
        </component>
    </div>
</template>

<script>
export default {
    mounted(){
        this.historyPush();
        this.combackerPopstate(this.$route.path);
    },
    computed: {
        layout() {
            return this.$route.meta.layout || "layout-default";
        },
    },
    methods: {
        combackerPopstate(path) {
            window.addEventListener("popstate", function (event) {
                if (event.state == -1 && path === "/") {
                    window.location.href = "/showcase";
                }
            }, false);
        },
        historyPush() {
            history.pushState(-1, null);
            history.pushState(0, null);
            history.pushState(1, null);
            history.go(-1);
        }
    }
};
</script>
